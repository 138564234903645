import React, { useState } from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import { MainHeroHeadingH1, DescriptionDisplay1 } from '@components/ui/typography/Typography'
// import UnderlineHeading from '@components/common/UnderlineHeading'
import mountain from '@assets/images/home/hero/mountain.png'
import { StaticImage } from 'gatsby-plugin-image'
import ButtonVideo from '@components/ui/buttons/ButtonVideo'
import { useWindowSize } from '@configs/getGlobalWindowSize'

const HeroSectionV7Ai = ({ intl }) => {
  const [openModal, setOpenModal] = useState(false)

  const openModalHandler = () => {
    !openModal ? setOpenModal(true) : setOpenModal(false)
  }

  const currentWidth = useWindowSize().width

  return (
    <div>
      <SectionWrapper hidePaddingBottom showBackground backgroundImage={mountain}>
        <Wrapper>
          <Text>
            <div className="headingWrapper">
              <MainHeroHeadingH1>
                {intl.formatMessage({ id: 'home.hero.title.homepage' })}
                {/*{intl.formatMessage({ id: 'home.hero.title.bring' })}&nbsp;*/}
                {/*<UnderlineHeading*/}
                {/*  text={intl.formatMessage({ id: 'home.hero.title.business' })}*/}
                {/*  display="block"*/}
                {/*  mdDisplay="inline-block"*/}
                {/*  xlDisplay="inline-block"*/}
                {/*  mdMargin="0"*/}
                {/*  height="50px"*/}
                {/*  xlHeight="60px"*/}
                {/*/>*/}
                {/*&nbsp;{intl.formatMessage({ id: 'home.hero.title.life' })}*/}
              </MainHeroHeadingH1>
            </div>
            <div className="descriptionWrapper">
              <DescriptionDisplay1>{intl.formatMessage({ id: 'home.hero.homepageDescription' })}</DescriptionDisplay1>
            </div>
            <div className="buttonLinkWrapper">
              <div className="buttons">
                <ButtonLink
                  to="/pricing"
                  // external
                  // href={generateAppLink('signup', intl.locale)}
                  buttonBackground={color.blue}
                  buttonColor={color.white}
                  buttonText={intl.formatMessage({ id: 'home.hero.startFree' })}
                  smfontsize={'16px'}
                  mdfontsize={'16px'}
                />
              </div>
              <p className="text">{intl.formatMessage({ id: 'home.hero.try' })}</p>
            </div>
          </Text>
        </Wrapper>

        <SliderWrapper>
          <ImageVideo onClick={openModalHandler}>
            <VideoButtonWrapper>
              <ButtonVideo openModal={openModal} videoId="Mb0-CeTuzY8" buttonColor="red" />
            </VideoButtonWrapper>
            <StaticImage src="../../assets/images/home/hero/heroImage7Ai.webp" alt="Hero image" />

            {/*{currentWidth > 768 ? (*/}
            {/*  <StaticImage src="../../assets/images/home/hero/heroImageNewConcept.webp" alt="Hero image" />*/}
            {/*) : (*/}
            {/*  <StaticImage src="../../assets/images/home/hero/heroImageNewConceptMobile.webp" alt="Hero image" />*/}
            {/*)}*/}
          </ImageVideo>
        </SliderWrapper>
      </SectionWrapper>
    </div>
  )
}

export default injectIntl(HeroSectionV7Ai)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoint.sm}) {
    align-items: center;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    padding: 0 10%;
  }
`

const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;

  .headingWrapper {
    text-align: center;
    max-width: 800px;
  }

  .descriptionWrapper {
    max-width: 450px;
    text-align: center;
    @media screen and (min-width: ${breakpoint.md}) {
      max-width: 600px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      max-width: 800px;
    }
  }

  .buttonLinkWrapper {
    width: 100%;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @media screen and (min-width: ${breakpoint.md}) {
      padding-bottom: 40px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      @media screen and (min-width: ${breakpoint.md}) {
        flex-direction: row;
        column-gap: 12px;
      }
    }

    .text {
      color: ${color.font};
      text-align: center;
      opacity: 0.38;
      font-size: 12px;
    }
  }
`

const SliderWrapper = styled.div`
  margin: 0 auto;
  background-color: #f5f7fb;
  border-radius: 12px;
  border: 8px solid #081e4a;
  width: 100%;

  @media screen and (min-width: ${breakpoint.md}) {
    border-radius: 18px;
    border: 13px solid #081e4a;
    width: 65%;
  }
`

const ImageVideo = styled.div`
  cursor: pointer;
  position: relative;
  box-shadow: 0 5px 30px rgba(8, 30, 74, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
`

const VideoButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
`
